import type { Maybe } from "@vue-storefront/unified-data-model";
import { toRefs } from "@vueuse/core";

export type Price = {
  product_sku: string;
  branch_id: number;
  price: number;
  price_inc_vat: number;
  unit: string;
  offer_price: number;
  offer_price_inc_vat: number;
  offer_unit: string;
};

export interface UsePricesState {
  prices: Maybe<Price[]>;
}

const usePrices = () => {
  const { selectedBranchId, selectedBranch } = useBranches();
  const { user } = useUser();
  const state = useState<UsePricesState>(`usePrices`, () => ({
    prices: [],
  }));

  const getPrice = async (productsSku: any) => {
    if (
      !selectedBranchId.value ||
      !selectedBranch.value ||
      !user.value ||
      user.value?.account?.terms === selectedBranch.value?.pricing?.terms ||
      !productsSku
    ) {
      return;
    }

    const { NUXT_PUBLIC_API_PROTOCOL, NUXT_PUBLIC_API_BASE_URL } = useRuntimeConfig().public;
    const headers = useRequestHeaders();

    const { data: priceData, error } = await useAsyncData("getPrice", () =>
      $fetch<any>(`${NUXT_PUBLIC_API_PROTOCOL}://${NUXT_PUBLIC_API_BASE_URL}/middleware/getPrices`, {
        headers,
        body: JSON.stringify({ productsSku }),
        method: "POST",
        credentials: "include",
      }),
    );
    if (error.value) {
      useHandleError({
        status: 404,
        message: "An error occurred",
        statusMessage: "An error occurred",
      });
    }

    return priceData.value;
  };

  const fetchPrice = async (productsSku: string) => {
    if (
      !selectedBranchId.value ||
      !selectedBranch.value ||
      !user.value ||
      user.value?.account?.terms === selectedBranch.value?.pricing?.terms ||
      !productsSku
    ) {
      return;
    }

    const { NUXT_PUBLIC_API_PROTOCOL, NUXT_PUBLIC_API_BASE_URL } = useRuntimeConfig().public;
    const headers = useRequestHeaders();

    const { data: priceData, error } = await useAsyncData("fetchPrice", () =>
      $fetch<Price[]>(`${NUXT_PUBLIC_API_PROTOCOL}://${NUXT_PUBLIC_API_BASE_URL}/middleware/getPrices`, {
        headers,
        body: JSON.stringify({ productsSku }),
        method: "POST",
        credentials: "include",
      }),
    );

    useHandleError(error.value);

    state.value.prices = priceData.value;
  };

  const findPriceObjById = (skuId: string) => {
    return state.value.prices ? state.value.prices.find((price) => price.product_sku === skuId) : null;
  };

  return {
    getPrice,
    fetchPrice,
    findPriceObjById,
    ...toRefs(state.value),
  };
};

export default usePrices;
